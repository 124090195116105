import React, { Suspense, useState } from "react";
import { fullScreenContainer } from "../styles/general";
import { AppBar, Button, IconButton, Tab, Tabs, Typography, Box, Fade, LinearProgress } from "@mui/material";
import { StorageManager } from "@unity/components";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

//const MyJobs = React.lazy(() => import("../modules/MyJobs"));
const MyClosedJobs = React.lazy(() => import("../modules/MyClosedJobs"));
const MyNeglectedJobs = React.lazy(() => import("../modules/MyNeglectedJobs"));
const MyCurrentJobs = React.lazy(() => import("../modules/MyCurrentJobs"));
const MyTemplatedJobs = React.lazy(() => import("../modules/MyTemplatedJobs"));
const MyJobs = React.lazy(() => import("../modules/MyJobs"));
const MyDashboard = React.lazy(() => import("../modules/MyDashboard"));
const AllUsers = React.lazy(() => import("../modules/AllUsers"));

const LoadingComponent = ({component}) => (
    <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
        {component}
    </Suspense>
)


export default function Jobs({context}) {
  const [tab, setTab] = useState(0);
  const [subpage, setSubpage] = useState("Closed");
  let history = useHistory();

  // -------------------------------------------------------------------------------
  // This is just an example of how we are implementing session cache at the moment.
  // If you use make sure the module name is part of the key to avoid conflicts!
  const sm = new StorageManager();

  if(sm.checkGrant()) {
    let t = parseInt(sm.getItem("jobs-index-tab", 0));
    if(!isNaN(t) && t !== tab) {
        setTab(t);
    }
  }

  const handleTabChange = (value) => {
    setTab(value);
    switch(value){
        case 0: setSubpage("Dashboard"); break;
        case 1: setSubpage("Users"); break;
        case 2: setSubpage("Current"); break;
        case 3: setSubpage("Closed"); break;
        case 4: setSubpage("Neglected"); break;
        case 5: setSubpage("Templated"); break;
        case 6: setSubpage("All"); break;
        default: setSubpage("Current");
    }
    sm.setItem("jobs-index-tab", value);
  }
  // -------------------------------------------------------------------------------

  const getTab = () => {
    if(!isMobile)
    {
        switch(tab) {
            case 0: return <LoadingComponent component={<MyDashboard context={context}/>} />; break;
            case 1: return <LoadingComponent component={<AllUsers context={context}/>} />; break;
            case 2: return <LoadingComponent component={<MyCurrentJobs context={context}/>} />; break;
            case 3: return <LoadingComponent component={<MyClosedJobs context={context}/>} />; break;
            case 4: return <LoadingComponent component={<MyNeglectedJobs context={context}/>} />; break;
            case 5: return <LoadingComponent component={<MyTemplatedJobs context={context}/>} />; break;
            case 6: return <LoadingComponent component={<MyJobs context={context}/>} />; break;
            default: return (
                <Typography 
                    variant="h5" 
                    gutterBottom
                >
                    Tab Not Found. Please Refresh.
                </Typography>
            )
        }
    }
    else
    {
        return <LoadingComponent component={<MyCurrentJobs context={context}/>} />;
    }
  }
        return (
            <>
            {!isMobile ?
            <Box>
                <Box
                    style={{
                        backgroundColor: "rgb(33, 0, 183)",
                        height: "90px",
                        padding: "2px",
                        width:"100%",         
                        top: 0,
                    }}>
                    <IconButton
                        onClick={() => {window.location.pathname = `/jobs/index`}}
                        onTouchStart={() => {window.location.pathname = `/jobs/index`}}
                        style={{
                            paddingHorizontal: "16px",
                            paddingVertical: "8px",
                            borderRadius:"4px",
                            backgroundColor: "rgb(255, 255, 255)",
                            fontSize: "0.875rem",
                            height: "40px",
                            color:'#000000',
                            width: "64px",
                            top: "25px",
                            left: "10px"
                        }}
                    >
                        <KeyboardBackspace />
                    </IconButton>
                    { context.auth.access["jobs"]["RA"] ?
                    <Button
                        onClick={() => {window.location.pathname = `/jobs/create`}}
                        onTouchStart={() => {window.location.pathname = `/jobs/create`}}
                        style={{
                            paddingHorizontal: "16px",
                            paddingVertical: "8px",
                            borderRadius:"4px",
                            backgroundColor: "rgb(245, 0, 0)",
                            fontSize: "0.875rem",
                            height: "40px",
                            color:'#ffffff',
                            width: "164px",
                            top: "25px",
                            left: "50%"
                        }}
                    >
                    + ADD NEW JOB
                    </Button>
                    : null }
                    <Typography 
                        variant="h6"
                        style={{
                            color:'#ffffff',
                            marginLeft: "100px",
                            paddingTop: "0px",
                            marginTop: "-10px"
                        }}>
                        Jobs {<IoMdArrowDropright/>} {subpage}
                    </Typography>

                
                </Box>
                <Box>
                    <AppBar position="static">
                        <Tabs
                            value={tab}
                            onChange={(e, val) => {handleTabChange(val)}}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            sx={[{
                                backgroundColor: '#fff',
                                color: '#212529'
                            }]}
                        >
                            <Tab 
                                label="Dashboard"
                            />
                            <Tab 
                                label="Users"
                            />
                            <Tab 
                                label="Current Jobs"
                            />
                            <Tab 
                                label="Closed Jobs"
                            />
                            <Tab 
                                label="Neglected Jobs"
                            />
                            <Tab 
                                label="Templated Jobs"
                            />
                            <Tab 
                                label="All Jobs"
                            />
                        </Tabs>
                    </AppBar>
                </Box>
            </Box>
            :
            <Box>
                <Box
                    style={{
                        backgroundColor: "rgb(33, 0, 183)",
                        height: "90px",
                        padding: "2px",
                        width:"100%",        
                        top: 0,
                }}>
                    <IconButton
                        onClick={() => {window.location.pathname = `/jobs/index`}}
                        onTouchStart={() => {window.location.pathname = `/jobs/index`}}
                        style={{
                            paddingHorizontal: "16px",
                            paddingVertical: "8px",
                            borderRadius:"4px",
                            backgroundColor: "rgb(255, 255, 255)",
                            fontSize: "0.875rem",
                            height: "40px",
                            color:'#000000',
                            width: "64px",
                            top: "25px",
                            left: "30px"
                        }}
                    >
                        <KeyboardBackspace />
                    </IconButton>
                    <Typography 
                        variant="h6"
                        style={{
                            color:'#ffffff',
                            marginLeft: "100px",
                            paddingTop: "0px",
                            marginTop: "-10px"
                        }}>
                        Jobs
                    </Typography>
                </Box>
            </Box>
            }

                <div>
                    { getTab() }
                </div>
            </>
        )
}
