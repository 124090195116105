import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Jobs from "./pages/index";
import JobsCreate from "./pages/create";
import JobsView from "./pages/view";
import JobsEdit from "./pages/edit";
import JobsCancelled from "./pages/cancelled";
import JobsBehind from "./pages/behind";
import JobsComplete from "./pages/complete";
import { CookieRequestComponent } from "@unity/components";


export default function Root(props) {
  
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <CookieRequestComponent />

      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && (
                        <Route
                          path="/jobs/index" exact component={(route) => ( <Jobs route={route} context={context} />
                          )}
                        />
                    )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && props.auth.access["jobs"]["C"] && (
                        <Route
                          path="/jobs/create" exact component={(route) => ( <JobsCreate route={route} context={context} />
                          )}
                        />
                      )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && (
                        <Route
                          path="/jobs/view" exact component={(route) => ( <JobsView route={route} context={context} />
                          )}
                        />
                      )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && props.auth.access["jobs"]["U"] && (
                        <Route
                          path="/jobs/edit" exact component={(route) => ( <JobsEdit route={route} context={context} />
                          )}
                        />
                      )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && (
                        <Route
                          path="/jobs/cancelled" exact component={(route) => ( <JobsCancelled route={route} context={context} />
                          )}
                        />
                      )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && (
                        <Route
                          path="/jobs/behind" exact component={(route) => ( <JobsBehind route={route} context={context} />
                          )}
                        />
                      )}
                    {props.auth.access["jobs"]["A"] && props.auth.access["jobs"]["R"] && (
                        <Route
                          path="/jobs/complete" exact component={(route) => ( <JobsComplete route={route} context={context} />
                          )}
                        />
                      )}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
