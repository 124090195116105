import React, { Suspense, useState } from "react";
import { AppBar, Button, IconButton, Tab, Tabs, Typography, Box, Fade, LinearProgress } from "@mui/material";
import { StorageManager } from "@unity/components";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
const ViewJob = React.lazy(() => import("../modules/ViewJob"));
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const LoadingComponent = ({component}) => (
    <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
        {component}
    </Suspense>
)


export default function JobsView({context}) {
  let history = useHistory();

  // -------------------------------------------------------------------------------
  // This is just an example of how we are implementing session cache at the moment.
  // If you use make sure the module name is part of the key to avoid conflicts!
  const sm = new StorageManager();

  // -------------------------------------------------------------------------------

  const getTab = () => {
    return <LoadingComponent component={<ViewJob context={context}/>} /> 
  }

  return (
    <>
        <Box
            style={{
                backgroundColor: "rgb(33, 0, 183)",
                height: "90px",
                padding: "2px",
                width:"100%",         
                top: 0,
            }}>
            {!isMobile ?
            <Box>
                <IconButton
                    onClick={() => history.goBack()}
                    onTouchStart={() => history.goBack()}
                    style={{
                        paddingHorizontal: "16px",
                        paddingVertical: "8px",
                        borderRadius:"4px",
                        backgroundColor: "rgb(255, 255, 255)",
                        fontSize: "0.875rem",
                        height: "40px",
                        color:'#000000',
                        width: "64px",
                        top: "25px",
                        left: "10px"
                    }}
                >
                    <KeyboardBackspace />
                </IconButton>       
                <Button
                    style={{
                        paddingHorizontal: "16px",
                        paddingVertical: "8px",
                        borderRadius:"4px",
                        backgroundColor: "rgb(245, 0, 0)",
                        fontSize: "0.875rem",
                        height: "40px",
                        color:'#ffffff',
                        width: "164px",
                        top: "25px",
                        left: "50%"
                    }}
                    onClick={() => {window.location.pathname = `/jobs/edit`}}
                    onTouchStart={() => {window.location.pathname = `/jobs/edit`}}
                >
                Edit
                </Button>
                <Typography 
                    variant="h6"
                    style={{
                        color:'#ffffff',
                        marginLeft: "100px",
                        paddingTop: "0px",
                        marginTop: "-10px"
                    }}>
                    Jobs {<IoMdArrowDropright/>} View
                </Typography>
            </Box>
            :
            <Box>
                <Box
                    style={{
                        backgroundColor: "rgb(33, 0, 183)",
                        height: "90px",
                        padding: "2px",
                        width:"100%",        
                        top: 0,
                }}>
                    <IconButton
                        onClick={() => {window.location.pathname = `/jobs/index`}}
                        onTouchStart={() => {window.location.pathname = `/jobs/index`}}
                        style={{
                            paddingHorizontal: "16px",
                            paddingVertical: "8px",
                            borderRadius:"4px",
                            backgroundColor: "rgb(255, 255, 255)",
                            fontSize: "0.875rem",
                            height: "40px",
                            color:'#000000',
                            width: "64px",
                            top: "25px",
                            left: "30px"
                        }}
                    >
                        <KeyboardBackspace />
                    </IconButton>
                    <Typography 
                        variant="h6"
                        style={{
                            color:'#ffffff',
                            marginLeft: "100px",
                            paddingTop: "0px",
                            marginTop: "-10px"
                        }}>
                        Jobs
                    </Typography>
                </Box>
            </Box>
            }

           
        </Box>

        <div>
            { getTab() }
        </div>
    </>
  )
}
